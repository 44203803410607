import { useDispatch } from 'react-redux';
import { RiCloseLine } from "react-icons/ri";
import styles from './PopupConfirm.module.scss'



const PopupConfirm = props => {
  const dispatch = useDispatch()

  const handleCancel = () => {
    dispatch({ type: 'POPUP_CONFIRM', popupConfirm: false })
    props.onCancel?.()
  }
  const handleConfirm = () => {
    dispatch({ type: 'POPUP_CONFIRM', popupConfirm: false })
    props.onConfirm()
  }


  return (
    <div className={styles.wrap}>
      <div className={styles.mask} />

      <div className={styles.popup}>
        <div className={styles.title} style={{ marginBottom: props.title ? '15px' : null }}>
          {props.title}
          <div className={styles.closeIcon} onClick={handleCancel}>
            <RiCloseLine />
          </div>
        </div>

        <div className={styles.content}>
          {props.content}
        </div>

        <div className={styles.actions}>
          {props.onConfirm &&
            <button
              className={styles.buttonLine}
              onClick={handleCancel}
              type='button'
            >
              {props.cancelText ?? '取消'}
            </button>}

          <button
            className={styles.buttonFill}
            onClick={props.onConfirm ? handleConfirm : handleCancel}
            type='button'
          >
            {props.confirmText ?? ' 確定'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PopupConfirm
