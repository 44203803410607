import styles from './FlashMessage.module.scss'
import { ReactComponent as Path } from './icon-path.svg'


const FlashMessage = ({ type: success, content, duration = 1.2 }) => {

  return <>
    <div
      className={styles.mask}
      style={{ animationDuration: `${duration}s` }}
    />
    {success
      ? (
        <div
          className={styles.wrap}
          style={{ animationDuration: `${duration}s` }}
        >
          <div className={styles.success}><Path /></div>
          <span>{content ?? 'Update Successful'}</span>
        </div>)
      : (
        <div
          className={styles.wrap}
          style={{ animationDuration: `${duration}s` }}
        >
          <div className={styles.error}><Path /></div>
          <span>{content ?? 'Update Failed'}</span>
        </div>
      )}
  </>
}

export default FlashMessage