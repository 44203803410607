import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './SimpleLoader.module.scss';

const SimpleLoader = () => {
  const loading = useSelector(state => state.loading)
  const [exceedMinTime, setExceedMinTime] = useState(true);
  const min_time = 1000;

  useEffect(() => {
    if (loading) setExceedMinTime(false);
    setTimeout(() => setExceedMinTime(true), min_time);
  }, [loading])

  return (loading || !exceedMinTime) &&
    <div className={styles.wrap}>
      <div className={styles.loaderWrapper}>
        <svg className={styles.loader} viewBox='25 25 50 50'>
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="hsl(193,90%,55%)" />
              <stop offset="100%" stopColor="#1890ff" />
            </linearGradient>
          </defs>
          <circle
            className={styles.circle}
            cx='50' cy='50' r='20' fill='none'
            stroke="url(#color)" strokeWidth='1.5'
          />
        </svg>
        <span className={styles.text}>loading</span>
      </div>
    </div>
}

export default SimpleLoader