import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import axios from 'axios';
import { Input, Button } from 'antd';
import { AiOutlineUser, AiOutlineLock  } from "react-icons/ai";

import styles from './Login-2.module.scss';
import { login, getData, create } from 'actions';
import GoogleAuth from './GoogleAuth';
import FlashMessage from 'components/flash-message/FlashMessage';


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    window.handleToken = async e => {
        dispatch({type: 'LOADING', loading: true});

        await create("auth/login", {token: e.credential})
            .then(response => {
                localStorage.setItem("token", response.token);
                navigate(location.state?.from.path || '/dashboard');
            })
    }

    const handleSubmit = async e => {
        e.preventDefault();

        const el = e.target.elements;
        const params = {
            email: el.email.value,
            password: el.password.value,
            code: el.code.value,
        };

        dispatch({type: 'LOADING', loading: true});
        
        await login(params)
            .then(async () => {
                await axios.all([
                    getData('user/profile'),
                    getData('nav')
                ])
                .then(response => {
                    localStorage.setItem('menu', response[0].menu); /* horizontal or vertical */
                    localStorage.setItem('navigations', JSON.stringify(response[1]));
                    navigate(location.state?.from.pathname || '/dashboard');
                })
            })
            .catch(err => {
                setErrorMessage(err?.response?.data?.message)
                setIsInvalid(true);
                setTimeout(() => setIsInvalid(false), 1500)
            });
    }

    return (
        <>
            <div className={styles.wrap}>
                <div className={styles.content}>
                    <form onSubmit={handleSubmit} className={styles.loginWrap}>
                        <Input name="email" placeholder="Email" type={'email'} autoComplete={'username'} prefix={<AiOutlineUser/>} />

                        <Input.Password
                            name="password"
                            placeholder="Password"
                            autoComplete={'current-password'}
                            visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                            prefix={<AiOutlineLock/>}
                        />

                        <Input name="code" placeholder="2FA Code" autoComplete={'one-time-code'} prefix={<AiOutlineLock/>}/>

                        <Button type="primary" htmlType="submit">Log in</Button>
                    </form>

                    <GoogleAuth />
                </div>
            </div>

            {isInvalid &&
                <FlashMessage success={false} content={errorMessage || 'Invalid email or password'} />}
        </>
    )
}

export default Login;