import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';


const breakPoints = size => ({
  desktopWide: size < 1460,
  desktop: size < 1260,
  tabletWide: size < 1060,
  tablet: size < 790,
  phablet: size < 560,
  phoneWide: size < 480,
  phone: size < 400
})

const init = {
  loading: true,
  breakPoint: breakPoints(window.innerWidth),
  sidebarCollapse: false,
  openMobileMenu: false,
  updateSuccess: null,
  popupConfirm: null,
  popupRelogin: false
}

const rootReducer = (state = init, action) => {
  switch (action.type) {
    case 'BREAK_POINT':
      return { ...state, breakPoint: action.breakPoint }
    case 'LOADING':
      return { ...state, loading: action.loading }
    case 'UPDATE_SUCCESS':
      return { ...state, updateSuccess: action.updateSuccess }
    case 'POPUP_CONFIRM':
      return { ...state, popupConfirm: action.popupConfirm }
    case 'POPUP_RELOGIN':
      return { ...state, popupRelogin: action.popupRelogin }
    case 'SIDEBAR_COLLAPSE':
      return { ...state, sidebarCollapse: action.sidebarCollapse }
    case 'OPEN_MOBILE_MENU':
      return { ...state, openMobileMenu: action.openMobileMenu }
    default:
      return state
  }
}

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

export default store;