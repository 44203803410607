import { useEffect } from 'react';
import styles from "./Login-2.module.scss";

const GoogleAuth = () => {
  useEffect(() => {
    const script = document.createElement('script'); 
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    document.body.append(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [])

  return (
    <div className={styles.googleWrap}>
      <div 
        id="g_id_onload"
        data-client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        data-callback="handleToken"
        data-auto_prompt="false"
        data-context="signin"
        data-ux_mode="popup"
      >
      </div>
      <div className="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="filled_blue"
        data-text="signin"
        data-size="large"
        data-logo_alignment="left">
      </div>
    </div>
  )
}

export default GoogleAuth