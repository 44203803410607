import { CreditCardFilled } from '@ant-design/icons';
import { BsBoxSeam } from "react-icons/bs";
import { RiBarChart2Fill, RiUserFill, RiShoppingBag2Fill } from "react-icons/ri";



const icons = {
  dashboard: <RiBarChart2Fill />,
  manufacturers: <RiShoppingBag2Fill />,
  products: <BsBoxSeam />,
  orders: <CreditCardFilled />,
  employees: <RiUserFill />
}

export default icons