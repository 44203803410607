/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios';
import { message } from 'antd';


const base = process.env.REACT_APP_BASE_URL

export const login = async data => {
  return await axios.post(`${base}/v1/auth/login`, JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json' }
  }).then(async response => {
    localStorage.setItem('token', response.data.data.token)
    return response
  })
}

export const getData = async (resource, params = {}) => {
  const api = resource + Object.keys(params)
    .reduce((x, k) => x + `&${k}=${params[k]}`, '')
    .replace(/[?|&]/, '?')

  return await axios.get(`${base}/v1/${api}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }).then(r => r.data.data)
}

export const update = async (resource, data, method = 'patch') => {
  return await axios[method](`${base}/v1/${resource}`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }).then(r => r.data.data)
}

export const create = async (resource, data) => {
  return await axios.post(`${base}/v1/${resource}`, data, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }).then(r => r.data.data)
}

export const deleteItem = async (resource, data) => {
  return await axios.delete(`${base}/v1/${resource}`, {
    data, headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }).then(r => r.data)
}

export const downloadFiles = async props => {
  const { resource, method, params = {}, fileName, extension, dispatch } = props

  const blob = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    responseType: 'blob'
  }

  dispatch({ type: 'LOADING', loading: true })
  return await axios.all([
    method === 'get'
      ? axios.get(`${base}/v1/${resource}`, blob)
      : axios.post(`${base}/v1/${resource}`, params, blob)
  ])
    .then(async ([response]) => {
      if (response.data?.type === 'application/json') {
        let data = JSON.parse(await response.data.text());

        if ((typeof (data?.data) === 'object' && typeof (data.data?.result) === 'string' && data.data.result === 'queued') ||
          (typeof (data?.data === 'string' && data?.data === 'queued'))) {
          dispatch({
            type: 'UPDATE_SUCCESS',
            updateSuccess: { type: true, content: '匯出中, 完成後將發信通知。', duration: 1.5 }
          })

          return
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}${extension}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(err => {
      const reader = new FileReader();
      reader.onload = () => {
        const { code, message } = JSON.parse(reader.result)
        let content = 'PDF 下載失敗'

        if (code === -7001) content = message

        dispatch({
          type: 'UPDATE_SUCCESS',
          updateSuccess: { type: false, content, duration: 1.5 }
        })
      }
      reader.readAsText(err.response.data);
    })
    .finally(() => {
      dispatch({ type: 'LOADING', loading: false })
    })
}

export const sfShippingLabel = async (data, dispatch) => {
  let api = `${base}/v1/orders/exports/shipment-labels`
  dispatch({ type: 'LOADING', loading: true })

  axios.post(api, data, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
    .then(({ data }) => {
      window.open(data.data.url);
    })
    .catch((r) => {
      message.error({ duration: 2, content: r.response.data.message })
    })
    .finally(() => {
      setTimeout(() => dispatch({ type: 'LOADING', loading: false }), 1000)
    })
}
