/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import axios from "axios"
import classNames from 'classnames';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { HiMenuAlt2 } from "react-icons/hi";
import { RiArrowRightSLine, RiBookmarkFill } from "react-icons/ri";

import styles from './Layout.module.scss';
import icons from './menuIcons';
import FlashMessage from 'components/flash-message/FlashMessage';
import PopupConfirm from 'components/popup-confirm/PopupConfirm';
import Relogin from './relogin/Relogin';
import { getData } from 'actions';
import SimpleLoader from './simple-loader/SimpleLoader';
import ServerTimer from './ServerTimer';

const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION

const Layout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const mbMenuMaskRef = useRef(null)

  const {
    sidebarCollapse,
    openMobileMenu,
    updateSuccess,
    popupConfirm,
    breakPoint
  } = useSelector(state => state)

  const [navigations, setNavigations] = useState(JSON.parse(localStorage.getItem('navigations')))
  const [menuOrientation, setMenuOrientation] = useState(JSON.parse(localStorage.getItem('menu')))

  const [openMbMenu, setOpenMbMenu] = useState(false)
  const [optionMenu, setOptionMenu] = useState(navigations)
  const [popupLogin, setPopupLogin] = useState(false)
  const [requestsToRefresh, setRequestsToRefresh] = useState([])



  const handleMenuOnClick = (e, path) => {
    e.preventDefault()
    dispatch({ type: 'LOADING', loading: true })
    navigate(path)
  }

  const handleLogout = () => {
    navigate('/login', { state: { from: location }, replace: true })
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
  }

  const hanldeMbMenuClick = ({ url, children, name }) => {
    if (children) return setOptionMenu({ name, children })

    dispatch({ type: 'LOADING', loading: true })
    navigate(url)
    dispatch({ type: 'OPEN_MOBILE_MENU', openMobileMenu: false })
  }


  useEffect(() => {
    if (updateSuccess === null) return
    setTimeout(() => {
      dispatch({ type: 'UPDATE_SUCCESS', updateSuccess: null })
      updateSuccess.closeModal?.()
    }, (updateSuccess.duration ?? 1.2) * 1000)
  }, [updateSuccess])

  useEffect(() => {
    if (openMobileMenu) setOpenMbMenu(true)
    else {
      mbMenuMaskRef.current?.classList.add(styles.collapse)
      setTimeout(() => setOpenMbMenu(false), 400)
    }
  }, [openMobileMenu])


  useLayoutEffect(() => {
    // check token validation
    const myInterceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        dispatch({ type: 'LOADING', loading: false });

        if (/login/.test(error.response.config.url)) {
          dispatch({
            type: 'UPDATE_SUCCESS',
            updateSuccess: {
              type: false,
              content: error?.response?.data?.message || 'Invalid email or password'
            }
          })
          return Promise.reject(error)
        }

        if (error.response.status === 401) setPopupLogin(true)

        if (error.response.status === 401 || error.response.status === 403) {
          return new Promise(res => {
            requestsToRefresh.push(token => {
              error.config.headers.Authorization = `Bearer ${token}`
              res(axios(error.config))
            })
          })
        }
        return Promise.reject(error)
      })

    return () => {
      axios.interceptors.response.eject(myInterceptor);
    }
  }, [])


  // update menu orientation
  useEffect(() => {
    setMenuOrientation(parseInt(localStorage.getItem('menu')))
  }, [localStorage.getItem('menu')])


  useEffect(() => {
    const fetchNavbar = async () => {
      await axios.all([
        getData('user/profile'),
        getData('nav')
      ])
        .then(([profile, nav]) => {
          localStorage.setItem('menu', profile.menu)
          localStorage.setItem('navigations', JSON.stringify(nav))

          setNavigations(nav)
          setMenuOrientation(profile.menu)
        })
    }
    if (navigations === null || menuOrientation === null) fetchNavbar()
  }, [])



  return <>
    <div className={classNames(styles.wrap, { [styles.vertical]: menuOrientation === 1 })}>
      <BrowserView show={menuOrientation === 1}>
        <div className={classNames(styles.sidebar, { [styles.collapse]: sidebarCollapse })}>
          <div
            className={styles.collapseIcon}
            onClick={() => dispatch({ type: 'SIDEBAR_COLLAPSE', sidebarCollapse: !sidebarCollapse })}
          >
            {sidebarCollapse ? <FaArrowRight /> : <FaArrowLeft />}
          </div>

          <div>
            {navigations?.map((menu, i) =>
              <div key={i} className={styles.menuWrapper}>
                <a
                  className={classNames(styles.menuText, { [styles.active]: isSamePath(menu, location) })}
                  href={`/${menu.url}`}
                  onClick={e => handleMenuOnClick(e, `/${menu.url}`)}
                >
                  {icons[menu.url] || <RiBookmarkFill />}
                  <span className={styles.text}>{menu.name}</span>
                </a>

                {(menu.children || sidebarCollapse) &&
                  <div className={styles.options}>
                    {(menu.children ?? [menu]).map((option, j) =>
                      <a key={j}
                        className={classNames(styles.option, { [styles.active]: new RegExp(`^/${option.url}`).test(location.pathname) })}
                        href={`/${option.url}`}
                        onClick={e => handleMenuOnClick(e, `/${option.url}`)}
                      >
                        {option.name}
                      </a>)}
                  </div>}
              </div>)}
          </div>
        </div>
      </BrowserView>

      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <MobileView>
            <div
              className={styles.mbMenuIcon}
              onClick={() => dispatch({ type: 'OPEN_MOBILE_MENU', openMobileMenu: true })}
            >
              <HiMenuAlt2 />
            </div>
          </MobileView>

          {!breakPoint.tablet && <ServerTimer />}

          <div className={styles.account}>
            <div className={classNames(styles.menuText, { [styles.active]: location.pathname === '/preference' })} >
              Account
            </div>
            <div className={styles.options}>
              <a className={styles.option} href='/preference' onClick={e => handleMenuOnClick(e, '/preference')}>
                <IoSettingsOutline />Preferences
              </a>
              <div className={styles.option}>
                Version: {(BUILD_VERSION || 'Dev').substring(0, 8)}
              </div>
              <div className={styles.option} onClick={handleLogout}>
                <MdLogout />Logout
              </div>
            </div>
          </div>
        </div>

        <BrowserView show={menuOrientation === 0}>
          <div className={styles.menubar}>
            {navigations?.map((menu, i) =>
              <div key={i} className={styles.menuWrapper}>
                <a
                  className={classNames(styles.menuText, { [styles.active]: isSamePath(menu, location) })}
                  href={`/${menu.url}`}
                  onClick={e => handleMenuOnClick(e, `/${menu.url}`)}
                >
                  {icons[menu.url] || <RiBookmarkFill />}
                  {menu.name}
                </a>

                {menu.children &&
                  <div className={styles.options}>
                    {menu.children.map((option, j) =>
                      <a key={j}
                        className={classNames(styles.option, { [styles.active]: new RegExp(`^/${option.url}`).test(location.pathname) })}
                        href={`/${option.url}`}
                        onClick={e => handleMenuOnClick(e, `/${option.url}`)}
                      >
                        {option.name}
                      </a>)}
                  </div>}
              </div>)}
          </div>
        </BrowserView>
      </div>

      <div className={classNames(styles.content, { [styles.vertical]: menuOrientation === 0 })}>
        <Outlet />
      </div>
    </div>

    {openMbMenu && <>
      <div
        ref={mbMenuMaskRef}
        className={styles.mbMenuMask}
        onClick={() => dispatch({ type: 'OPEN_MOBILE_MENU', openMobileMenu: false })}
      />
      <div className={styles.mbMenuWrapper}>
        {optionMenu?.name &&
          <div className={styles.header} onClick={() => setOptionMenu(navigations)}>
            <FaArrowLeft />{optionMenu.name}
          </div>}
        <div className={styles.mbMenu}>
          {(optionMenu?.children ?? optionMenu)?.map((item, i) =>
            <div key={i}
              className={classNames(styles.menuText, { [styles.active]: isSamePath(item, location) })}
              onClick={() => hanldeMbMenuClick(item)}
            >
              <span className={styles.text}>{item.name}</span>
              {item.children && <RiArrowRightSLine />}
            </div>)}
        </div>
      </div>
    </>}

    {<SimpleLoader />}

    {updateSuccess !== null && <FlashMessage {...updateSuccess} />}
    {popupConfirm && <PopupConfirm {...popupConfirm} />}
    {popupLogin &&
      <Relogin
        requestsToRefresh={requestsToRefresh}
        setRequestsToRefresh={setRequestsToRefresh}
        setPopupLogin={setPopupLogin}
      />}
  </>
}

const MobileView = ({ children, show = true }) => {
  const { tablet } = useSelector(state => state.breakPoint)
  return tablet && show && children
}

const BrowserView = ({ children, show = true }) => {
  const { tablet } = useSelector(state => state.breakPoint)
  return !tablet && show && children
}


const isSamePath = (menu, location) => {
  const regex = new RegExp(`${menu.url}(/.+)?$`)
  if (regex.test(location.pathname)) return true

  for (let option of menu.children || [])
    if (isSamePath(option, location)) return true
}

export default Layout