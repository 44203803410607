import { useState, useEffect } from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import styles from './Layout.module.scss';
import { getData } from 'actions';



const ServerTimer = () => {
  const [timer, setTimer] = useState({
    west: timezone('America/Los_Angeles'),
    east: timezone('America/New_York')
  })

  useEffect(() => {
    const init = async () => {
      await getData('global/serverTime')
        .then(response => {
          const delta = Date.now() - response.timestamp * 1000
          setInterval(() => {
            setTimer({
              west: timezone('America/Los_Angeles', delta),
              east: timezone('America/New_York', delta)
            })
          }, 1000)
        })
        .catch(() => {
          setInterval(() => {
            setTimer({
              west: timezone('America/Los_Angeles'),
              east: timezone('America/New_York')
            })
          }, 1000)
        })
    }
    init()
  }, [])

  return (
    <div className={styles.serverTimer}>
      <div className={styles.block}>
        <span>WEST</span>
        <span>{timer.west}</span>
      </div>
      <div className={styles.block}>
        <span>EST</span>
        <span>{timer.east}</span>
      </div>
    </div>
  )
}

const timezone = (zone, time = 0) => {
  const date = Date.now() - time
  const pattern = 'eee, dd MMM yyyy\xa0\xa0HH:mm:ss'
  return formatInTimeZone(date, zone, pattern)
}

export default ServerTimer