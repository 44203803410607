import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Alert, Input, Button } from 'antd';
import { AiOutlineUser, AiOutlineLock } from "react-icons/ai";

import styles from './Relogin.module.scss';
import { login, create } from 'actions';
import GoogleAuth from 'login/GoogleAuth';


const Relogin = ({ requestsToRefresh, setRequestsToRefresh, setPopupLogin }) => {
  const dispatch = useDispatch()
  const [passwordVisible, setPasswordVisible] = useState(false);

  window.handleToken = async e => {
    dispatch({ type: 'LOADING', loading: true });

    await create("auth/login", { token: e.credential })
      .then(async response => {
        localStorage.setItem("token", response.token);
        return await axios.all(requestsToRefresh.map((cb) => cb(response.token)))
          .then(() => {
            setPopupLogin(false)
            setRequestsToRefresh([]);
          })
      })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const el = e.target.elements;
    const params = {
      email: el.email.value,
      password: el.password.value,
      code: el.code.value,
    };

    dispatch({ type: 'LOADING', loading: true });

    await login(params)
      .then(async r => {
        return await axios.all(requestsToRefresh.map((cb) => cb(r.data.data.token)))
          .then(() => {
            setPopupLogin(false)
            setRequestsToRefresh([]);
          })
      })
  }

  useEffect(() => {
    dispatch({ type: 'LOADING', loading: false });
  }, [dispatch])

  return (
    <div className={styles.wrap}>
      <div className={styles.modal}>
        <Alert
          description="Your token has expired, please login to continue"
          type="error"
        />

        <div className={styles.content}>
          <form onSubmit={handleSubmit} className={styles.loginWrap}>
            <Input name="email" placeholder="Email" type={'email'} autoComplete={'username'} prefix={<AiOutlineUser />} />

            <Input.Password
              name="password"
              placeholder="Password"
              autoComplete={'current-password'}
              visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
              prefix={<AiOutlineLock />}
            />

            <Input name="code" placeholder="2FA Code" autoComplete={'one-time-code'} prefix={<AiOutlineLock />} />

            <Button className={styles.submit} type="primary" htmlType="submit">Log in</Button>
          </form>

          <GoogleAuth />
        </div>
      </div>
    </div>
  )
}

export default Relogin